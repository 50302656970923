import React from "react";
import { Link } from "gatsby";
import * as s from "./Listings.module.scss";
import DOMPurify from "isomorphic-dompurify";

export const Listings = ({ id, link, source, date, title, authors = "" }) => {
    return (
        <div className={s.listing} key={id}>
            <h5 className={s.listing__date}>{date} | {source}</h5>

            <h3>
                <Link
                    to={link?.url}
                    target={link?.target}
                    className={s.listing__link}
                >
                    {title}
                </Link>
            </h3>
            {authors?.trim() !== "" && (
                <div
                    className={`${s.listing__authors} small-body-copy`}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(authors),
                    }}
                />)
            }

            <div className={s.listing__icon}>
                <span className={s.listing__borderLine}></span>
                <Link
                    to={link?.url}
                    target={link?.target}
                    className={s.listing__borderLine}
                >
                    <span className={s.listing__borderLine__img}></span>
                </Link>
            </div>
        </div>
    );
};
