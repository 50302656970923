import React from "react";
import { Seo } from "../components/Helpers/Seo";
import { Listings } from "../components/Listings/Listings";
import * as s from "../styles/pages/join-us.module.scss";
import Layout from "../components/Layout/Layout";
import { Container } from "react-bootstrap";
import Header from "../components/Header/Header";
import { graphql } from "gatsby";
import DOMPurify from "isomorphic-dompurify";

// call out seo properties here
export const Head = ({ data }) => <Seo
    title={data.wpPage.seo.title}
    description={data.wpPage.seo.metaDesc}
    keywords={data.wpPage.seo.metaKeywords}
/>;

const JoinUsPage = ({ data }) => {
    const mainSection = data.wpPage.acfJoinUsPage.mainSection;
    const allJobs = data.allWpJob.nodes;

    return (
        <Layout footerBackgroundClass="bg-white">
            <Container fluid>
                <Header />
            </Container>
            <Container className={s.joinUs} style={{ minHeight: 700 + "px" }}>
                <h1
                    className={s.joinUs__title}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            mainSection.heading
                        ),
                    }}
                />
                {/* jobs listing */}
                {allJobs.length > 0 ?
                    (<div className="listing__wrapper">
                        {allJobs.map((job) => {
                            const {
                                id,
                                title,
                                acfJobs: { link, location },
                            } = job;
                            return (
                                <Listings
                                    id={id}
                                    title={title}
                                    meta={location}
                                    link={link}
                                />
                            );
                        })}
                    </div>)
                    : <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                mainSection.noJobsMessage
                            ),
                        }}
                    />}
            </Container>
        </Layout>
    );
};

export default JoinUsPage;

export const pageQuery = graphql`
    query {
        wpPage(databaseId: { eq: 191 }) {
            seo {
                metaDesc
                title
                metaKeywords
            }       
            acfJoinUsPage{
                mainSection {
                    heading
                    noJobsMessage
                }
            }
        }
        allWpJob {
            nodes {
                id
                title
                acfJobs {
                    location
                    link {
                        target
                        url
                    }
                }
            }
        }
        
    }
`;
